import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'

import logoIcon from '../../resources/images/logos/lensesio-logo-icon-b-vsmall.png'

class dataPrivacy extends React.Component {
  render() {
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
    return (
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />
        {/* <!-- ========== End User License Agreement ========== --> */}
        <section className="mt-5 py-5 px-2">
          <div className="container-1">
            <div className="row pt-4">
              {/* Legals / Company Name / Address */}
              <div className="col-md-12 pb-5">
                <table style={{ width: '100%' }} className="f-14 mb-5">
                  <tbody>
                    <tr>
                      <td align="left" className="text-align-right">
                        <b>Lenses.io Ltd</b> is a company registered in England
                        & Wales
                        <br />
                        Company Registered Number 09975716
                        <br />
                        VAT number: GB 231980705
                        <br />
                        Registered office address
                        <br />
                        C/O Corporation Service Company (Uk) Limited, 5
                        Churchill Place, 10th Floor,
                        <br />
                        London, E14 5HU, United Kingdom
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Addresses */}
              <div className="col-md-12">
                <div className="text-center">
                  <img
                    src={logoIcon}
                    alt="Lenses.io logo icon"
                    className="pb-5"
                  />
                  <br />
                  <br />
                  <br />
                  <p className="f-26 fw-600 roboto pb-5">
                    <span className="primary-text">Lenses ®</span>
                    <span></span>
                    <br />
                    <br />
                    Data Privacy Notice
                  </p>
                </div>
                <br />

                {/* Data Privacy Notice */}
                <h2 className="underline h3-like">
                  Data privacy for clients, customers, partners, suppliers and
                  contractors
                </h2>
                <p>
                  Lenses.io is committed to protecting and respecting your
                  privacy.
                </p>
                <p>
                  This policy sets out the basis on which any personal data we
                  collect from you, or that you provide to us, will be processed
                  by us. Please read the following carefully to understand our
                  views and practices regarding your personal data and how we
                  will treat it.
                </p>
                <p>
                  The rules on processing of personal data are set out in the
                  General Data Protection Regulation (the “GDPR”).
                </p>

                {/* Definitions */}
                <h3 className="pt-3 pb-3">1. Definitions</h3>
                <p>
                  <b>Data controller</b> - A controller determines the purposes
                  and means of processing personal data.
                </p>
                <p>
                  <b>Data processor</b> - A processor is responsible for
                  processing personal data on behalf of a controller.
                </p>
                <p>
                  <b>Data subject</b> - A natural person.
                </p>
                <p>
                  <b>
                    Categories of data: Personal data and special categories of
                    personal data
                  </b>
                </p>
                <p>
                  <b>Personal data</b> - The GDPR applies to ‘personal data’
                  meaning any information relating to an identifiable person who
                  can be directly or indirectly identified in particular by
                  reference to an identifier (as explained in Article 6 of
                  GDPR). For example name, passport number, home address or
                  private email address. Online identifiers include IP addresses
                  and cookies.
                </p>
                <p>
                  <b>Special categories personal data</b> - The GDPR refers to
                  sensitive personal data as ‘special categories of personal
                  data’ (as explained in Article 9 of GDPR). The special
                  categories specifically include genetic data, and biometric
                  data where processed to uniquely identify an individual. Other
                  examples include racial and ethnic origin, sexual orientation,
                  health data, trade union membership, political opinions,
                  religious or philosophical beliefs.
                </p>
                <p>
                  <b>Processing</b> - means any operation or set of operations
                  which is performed on personal data or on sets of personal
                  data, whether or not by automated means, such as collection,
                  recording, organisation, structuring, storage, adaptation or
                  alteration, retrieval, consultation, use, disclosure by
                  transmission, dissemination or otherwise making available,
                  alignment or combination, restriction, erasure or destruction.
                </p>
                <p>
                  <b>Third party</b> - means a natural or legal person, public
                  authority, agency or body other than the data subject,
                  controller, processor and persons who, under the direct
                  authority of the controller or processor, are authorised to
                  process personal data.
                </p>

                {/* Who we are */}
                <h3 className="pt-3 pb-3">2. Who we are</h3>
                <p>
                  <b>Lenses.io</b> is the data controller. This means we decide
                  how your personal data is processed and for what purposes. Our
                  contact details are: info@lenses.io
                </p>

                {/* Purpose */}
                <h3 className="pt-3 pb-3">
                  3. The purpose(s) of processing your personal data
                </h3>
                <p>We use your personal data for the following purposes:</p>
                <ul>
                  <li>
                    To fulfil a contractual obligation e.g. to send you your
                    order
                  </li>
                  <li>To maintain our own accounts and records</li>
                  <li>
                    To inform individuals and companies of relevant news, events
                    or activities
                  </li>
                  <li>
                    To inform individuals and companies of technical support
                    information, upgrades and new product releases
                  </li>
                  <li>
                    Legal Obligation – where there is statutory or other legal
                    requirement to share information
                  </li>
                </ul>

                {/* Data Categories */}
                <h3 className="pt-3 pb-3">
                  4. The categories of personal data concerned
                </h3>
                <p>
                  With reference to the categories of personal data described in
                  the definitions section, we process the following categories
                  of your data:
                </p>
                <ul>
                  <li>Name</li>
                  <li>Address</li>
                  <li>Email address</li>
                  <li>Phone numbers</li>
                  <li>Payment information</li>
                </ul>
                <p>
                  You may give us data about you by corresponding with us by
                  phone, email or otherwise. This includes information you
                  provide when you register to use our site, subscribe to our
                  service, place an order, participate in discussion boards or
                  other social media and enter a competition, promotion or
                  survey.
                </p>

                {/* Legal basis */}
                <h3 className="pt-3 pb-3">
                  5. What is our legal basis for processing your personal data?
                </h3>
                <p>a) Personal data (article 6 of GDPR)</p>
                <p>
                  Our lawful basis for processing your general personal data:
                </p>
                <table
                  className="f-18 table ml-4"
                  style={{ width: '90%', borderColor: 'gray' }}
                >
                  <tbody>
                    <tr>
                      <td className="pl-0 no-border">
                        {' '}
                        <b>HOW WE USE YOUR PERSONAL INFORMATION</b>{' '}
                      </td>
                      <td className="no-border">
                        {' '}
                        <b>OUR REASON (LEGAL BASIS)</b>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1"> Set up your Lenses.io Account </td>
                      <td className="p-1"> Legitimate interest </td>
                    </tr>
                    <tr>
                      <td className="p-1">
                        {' '}
                        Process Your Order / Notify you of its status{' '}
                      </td>
                      <td className="p-1"> Fulfilling a contract </td>
                    </tr>
                    <tr>
                      <td className="p-1">
                        {' '}
                        Manage your account and provide customer service to you{' '}
                      </td>
                      <td className="p-1">
                        {' '}
                        Legal Obligation / Legitimate interest (depending on
                        nature of service){' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1">
                        {' '}
                        To add you to a notification list for new product
                        release where you have expressed an interest{' '}
                      </td>
                      <td className="p-1"> Legitimate interest </td>
                    </tr>
                    <tr>
                      <td className="p-1">
                        {' '}
                        Marketing communications to inform you of events,
                        promotions, upgrades and new product releases{' '}
                      </td>
                      <td className="p-1"> Legitimate interest </td>
                    </tr>
                    <tr>
                      <td className="p-1">
                        {' '}
                        To detect, investigate and report financial crime{' '}
                      </td>
                      <td className="p-1"> Legal Obligation </td>
                    </tr>
                    <tr>
                      <td className="p-1">
                        {' '}
                        To Notify you or changes or enhancements to our services
                        or circumstances that may be relevant or of interest to
                        you{' '}
                      </td>
                      <td className="p-1"> Legitimate interest </td>
                    </tr>
                    <tr>
                      <td className="p-1">
                        {' '}
                        Logistics planning, demand forecasting, management
                        information and research{' '}
                      </td>
                      <td className="p-1"> Legitimate interest </td>
                    </tr>
                  </tbody>
                </table>

                {/* Sharing */}
                <h3 className="pt-3 pb-3">6. Sharing your personal data</h3>
                <p>
                  Your personal data will be treated as strictly confidential,
                  and will be shared only with:
                </p>
                <ul>
                  <li>Delivery partners for you to receive your services</li>
                  <li>
                    IT Companies; who support our product and other systems
                  </li>
                  <li>
                    Payment Processing; trusted third parties to securely take
                    and manage payments
                  </li>
                  <li>
                    Credit Reference Agencies; when you apply for credit with us
                    we will make searches about you with credit reference
                    agencies.
                  </li>
                </ul>

                {/* Duration */}
                <h3 className="pt-3 pb-3">
                  7. How long do we keep your personal data?
                </h3>
                <p>
                  We keep your personal data for no longer than reasonably
                  necessary. The length of time we retain it is determined by
                  several factors including the purpose for which we use that
                  information and our obligations under other laws. We may need
                  your personal information to establish, bring or defend legal
                  claims. For this purpose, we will always retain your personal
                  information for 7 years after the date it is no longer needed
                  by us for any of the purposes listed under ‘How we use tour
                  personal information’ above.
                </p>

                <p>
                  <b>Where is Data Stored and For How long?</b>
                </p>
                <ul>
                  <li>
                    All data are collected and stored in secure Cloud services:
                    CRM, Google Drive, Docusign, QuickBooks or XERO.
                  </li>
                  <li>
                    For clients or customers whose services are provided and we
                    are required to provide information, we will ensure they are
                    contractually bound to take all reasonable steps to ensure
                    that data is treated securely, in accordance with data
                    protection regulations and in compliance with this policy.
                  </li>
                  <li>
                    We will only store data for as long as is necessary: We will
                    delete information of inactive clients, customers,
                    suppliers, contractors or other 3rd party. We will delete
                    information when regulatory deadlines have expired. We will
                    delete information on your request.
                  </li>
                </ul>

                {/* Duration */}
                <h3 className="pt-3 pb-3">
                  8. Providing us with your personal data
                </h3>
                <p>
                  We require your personal data as it is a requirement necessary
                  to enter into a contract. Please also refer to ‘How We Use
                  Your Personal Information’.
                </p>

                {/* Your rights */}
                <h3 className="pt-3 pb-3">
                  9. Your rights and your personal data
                </h3>
                <p>
                  Unless subject to an exemption under the GDPR, you have the
                  following rights with respect to your personal data:
                </p>
                <ul>
                  <li>
                    The right to request a copy of the personal data which we
                    hold about you;
                  </li>
                  <li>
                    The right to request that we correct any personal data if it
                    is found to be inaccurate or out of date;
                  </li>
                  <li>
                    The right to request your personal data is erased where it
                    is no longer necessary to retain such data;
                  </li>
                  <li>
                    THE RIGHT TO WITHDRAW YOUR CONSENT TO THE PROCESSING AT ANY
                    TIME, WHERE CONSENT WAS YOUR LAWFUL BASIS FOR PROCESSING THE
                    DATA;
                  </li>
                  <li>
                    The right to request that we provide you with your personal
                    data and where possible, to transmit that data directly to
                    another data controller, (known as the right to data
                    portability), (where applicable i.e. where the processing is
                    based on consent or is necessary for the performance of a
                    contract with the data subject and where the data controller
                    processes the data by automated means);
                  </li>
                  <li>
                    The right, where there is a dispute in relation to the
                    accuracy or processing of your personal data, to request a
                    restriction is placed on further processing;
                  </li>
                  <li>
                    The right to object to the processing of personal data,
                    (where applicable i.e. where processing is based on
                    legitimate interests (or the performance of a task in the
                    public interest/exercise of official authority); direct
                    marketing and processing for the purposes of
                    scientific/historical research and statistics).
                  </li>
                </ul>

                {/* Further processing */}
                <h3 className="pt-3 pb-3">12. Further processing</h3>
                <p>
                  If we wish to use your personal data for a new purpose, not
                  covered by this Data Privacy Notice, then we will provide you
                  with a new notice explaining this new use prior to commencing
                  the processing and setting out the relevant purposes and
                  processing conditions.
                </p>

                {/* Changelog */}
                <h3 className="pt-3 pb-3">13. Changes to our privacy policy</h3>
                <p>
                  Any changes we may make to our privacy policy in the future
                  will be posted on this page and, where appropriate, notified
                  to you by e-mail. Please check back frequently to see any
                  updates or changes to our privacy policy.
                </p>

                {/* Complaints */}
                <h3 className="pt-3 pb-3">14. How to make a complaint</h3>
                <p>
                  To exercise all relevant rights, queries or complaints please
                  in the first instance contact our data protection officer on
                  info@lenses.io
                </p>

                <p>
                  If this does not resolve your complaint to your satisfaction,
                  you have the right to lodge a complaint with the Information
                  Commissioners Office on 03031231113 or via email
                  https://ico.org.uk/global/contact-us/email/ or at the
                  Information Commissioner&apos;s Office, Wycliffe House, Water
                  Lane, Wilmslow, Cheshire, SK9 5AF, England.
                </p>
                <p>
                  Lenses.io Information Commissioners Office registration
                  number: <mark>ZA483037</mark>
                </p>

                <hr className="pt-2" />
                <p className="pt-2">
                  Last update: 11 Oct 2021
                  <br />
                  <br />
                  <small>
                    {' '}
                    The data privacy notice may be updated from time to time,
                    with the understanding that any such updates will not
                    materially reduce privacy terms experienced by the parties.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default dataPrivacy

export const pageQuery = graphql`
  query legalsDataPrivacy {
    allContentfulPages(
      filter: { pageReference: { pageIdentifier: { eq: "Legals_Policy" } } }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
